import React from 'react'
import {Button} from '../ButtonElements'

import {InfoContainer, InfoWrapperCopticChurch, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img} from './InfoElements'


const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description1, description2, description3, buttonLabel, img, alt, primary, dark, dark2}) => {
    return (
        <>
          <InfoContainer lightBg={lightBg} id={id}>
              <InfoWrapperCopticChurch>
                  <InfoRow imgStart={imgStart}>
                      <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description1}</Subtitle>
                            <Subtitle darkText={darkText}>{description2}</Subtitle>
                            <Subtitle darkText={darkText}>{description3}</Subtitle>
                        </TextWrapper>
                      </Column1>
                      <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt} style={{width: "90%", marginLeft: "5%", marginRight: "5%"}}/>
                            {/* <Img src={img} alt={alt} style={{width: "600px", height: "500px"}}/> */}
                        </ImgWrap>
                      </Column2>
                  </InfoRow>
              </InfoWrapperCopticChurch>
              </InfoContainer>  
        </>
    )
}

export default InfoSection
