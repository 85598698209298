import aboutImage from "../../images/about.png"
import churchImage from "../../images/coptic-cross.png"
import mentorshipImage from "../../images/mentorship.svg"
import contactImage from "../../images/account.svg"
import ImageFour from "../../images/svg-4.svg"


export const aboutObj = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Coptic Lawyers Worldwide',
    headline: 'About Us',
    description1: 'Coptic Lawyers Worldwide (CLW) is a non-profit society uniting the minority group of Copts around the world to share experiences and create opportunities.',
    description2: 'We aim to create a community to help students in commencing their path in serving the law. Alongside this, we hope to provide resources to all our members and aid them in developing their network through building and interacting with a diverse group of peers located all over the world.',
    buttonLabel: 'Get started',
    imgStart: false,
    img: aboutImage,
    alt: 'about',
    dark: true,
    primary: true,
    darkText: false
}

export const churchObj = {
    id: 'copticchurch',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Coptic Orthodox Christianity',
    headline: 'What does Coptic mean?',
    description1: 'It comes from the Greek word "aegytpos", which was used to describe the people of Egypt. For 1500 years it has been the word used to describe the Christian community in Egypt. Now, it represents the ancient Christian Church of Egypt which has preserved the same ancient faith, and is now found in almost every country around the world.',
    description2: 'It is not a closed community. Many Western people have chosen to become Orthodox Christians among its numbers. But its spiritual tradition, history, worship and prayers have all been formed in 2000 years of Christian experience in Egypt, often at great cost and in the face of violence.',
    description3: 'To be Coptic is to be connected to this community in one way or another, and to be part of its continuing life and service in the modern world.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: churchImage,
    alt: 'copticchurch',
    dark: false,
    primary: false,
    darkText: true
}

export const mentorshipObj = {
    id: 'mentorship',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Get Involved',
    headline: 'Mentorship Programme',
    description1: 'We will match you with a mentor based on your jurisdiction and practice area of interest to allow you to receive guidance and career advice throughout your legal career. You can serve as a mentor, a mentee or both.',
    description2: 'To get involved with our programme or for more information press here, and we will connect you!',
    buttonLabel: 'Register Here',
    imgStart: false,
    img: mentorshipImage,
    alt: 'mentorship',
    dark: true,
    primary: true,
    darkText: false
}

export const teamObj = {
    id: 'profile'
}

export const profilesObj = {
    id: 'profile',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Bank',
    headline: 'About Us',
    description1: 'Coptic Lawyers Worldwide (CLW) is a non-profit society uniting the minority group of Copts around the world to share experiences and create opportunities.',
    description2: 'We aim to create a community to help students in commencing their path in serving the law. Alongside this, we hope to provide resources to all our members and aid them in developing their network through building and interacting with a diverse group of peers located all over the world.',
    buttonLabel: 'Get started',
    imgStart: false,
    // img: aboutImage,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}

export const contactObj = {
    id: 'contact',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Contact Us',
    headline: 'Get in Touch',
    description1: 'To find out more about Coptic Lawyers Worldwide and our initiatives contact us via email.',
    description2: 'To keep up to date with Coptic Lawyers Worldwide and our initiatives follow us on our social media.',
    buttonLabel: 'Start Now',
    imgStart: false,
    // issue with size and responsiveness
    img: contactImage,
    alt: 'Paper',
    dark: true,
    primary: true,
    darkText: false
}
